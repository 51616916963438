$(() => {
  const smoothscroll = () => {
    const speed = 500;
    const adminBarHeight =
      $('#wpadminbar').length > 0 ? $('#wpadminbar').height() : 0;
    const notifyBarHeight = $('#notify-bar').is(':visible') ? $('#notify-bar').height() : 0;
    const headerHeight = $('header').height() + adminBarHeight + notifyBarHeight;
    const postListTop = $('.facetwp-search');
    const facetTemplateTop = $('.facetwp-template');
    if ($('.facetwp-load-more').length == 0) {
      if (postListTop.length) {
        if (FWP.enable_scroll == true) {
          $('html, body').animate({scrollTop: $(postListTop).offset().top - headerHeight}, speed);
        }
      } else if (facetTemplateTop.length) {
        $('html, body').animate({scrollTop: $(facetTemplateTop).offset().top - headerHeight}, speed);
      }
    }
  };

  const mobilePaginationClassAdding = () => {
    if ($('.facetwp-facet-pagination').length > 0) {
      if ($(window).width() < 576) {
        const paginationElements = $('.facetwp-facet-pagination .facetwp-page');
        const dotsContent = '<a class="facetwp-page dots">…</a>';
        const activeIndex = $('.facetwp-facet-pagination').find('.active').index();
        const lastIndex = paginationElements.length - 1;
        paginationElements.each((i, e) => {
          const element = $(e);
          if (!(i === 0 || i === 1 || i === lastIndex - 1 || i === lastIndex || i === (activeIndex - 1) || i === activeIndex || i === (activeIndex + 1)))
            element.addClass('hide-mobile');
          if (i > 1 && i < (activeIndex - 1))
            element.addClass('left-items');
          if (i > (activeIndex + 1) && i < (lastIndex - 1))
            element.addClass('right-items');
        });
        const leftHiddenContent = $('.facetwp-facet-pagination .facetwp-page.hide-mobile.left-items');
        const rightHiddenContent = $('.facetwp-facet-pagination .facetwp-page.hide-mobile.right-items');
        if (typeof leftHiddenContent != 'undefined' && leftHiddenContent.length > 0) {
          leftHiddenContent.first().before(dotsContent);
          leftHiddenContent.remove();
        }
        if (typeof rightHiddenContent != 'undefined' && rightHiddenContent.length > 0) {
          rightHiddenContent.last().after(dotsContent);
          rightHiddenContent.remove();
        }
      }
    }
  };

  const hideFiltersWhenEmpty = () => {
    const allFacetOptions = $('.facetwp-type-fselect .fs-options');
    if (allFacetOptions.length > 0) {
      allFacetOptions.each((i, e) => {
        const element = $(e);
        if (element.find('div').length === 0) {
          element.closest('.bs-column').hide();
        } else {
          element.closest('.bs-column').show();
        }
      });
    }
  };

  $(document).on('facetwp-refresh', function () {
    if (FWP.soft_refresh == true) {
      FWP.enable_scroll = true;
    } else {
      FWP.enable_scroll = false;
    }
  });

  $(document).on('facetwp-loaded', function () {
    if (FWP.enable_scroll == true) {
      smoothscroll();
    }
    mobilePaginationClassAdding();
    hideFiltersWhenEmpty();
  });

  $(window).on("load resize", function (e) {
    mobilePaginationClassAdding();
    hideFiltersWhenEmpty();
  });
});