(($) => {
	$(
		'.bs-section--home-remove-the-friction .bs-slider-accordion--remove-friction '
	).each((index, accordion) => {
		const $accordion = $(accordion);
		$accordion.on('click', '.accordion__block__btn', (e) => {
			if (window.innerWidth < 768) {
				setTimeout(() => {
					$('body, html').animate(
						{
							scrollTop:
								$(e.currentTarget).offset().top -
								($('header').outerHeight() + 47),
						},
						500
					);
				}, 400);
			}
		});
	});
})(jQuery);
