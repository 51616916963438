import lottie from '../../node_modules/lottie-web';
(($) => {
	const dottedLine = $('.dotted-line-svg');
	const logoPaths = $('.logo-paths-svg');
	const dottedLineAnimFile = dottedLine.attr('data-src');
	const logoPathsAnimFile = logoPaths.attr('data-src');

	const dottedLineAnimation = lottie.loadAnimation({
		container: dottedLine.get(0),
		renderer: 'svg',
		loop: true,
		autoplay: true,
		path: dottedLineAnimFile,
	});

	const logoPathsAnimation = lottie.loadAnimation({
		container: logoPaths.get(0),
		renderer: 'svg',
		loop: true,
		autoplay: true,
		path: logoPathsAnimFile,
	});
})(jQuery);
