/* eslint-env jquery */
(($) => {
	const marqueeRows = $('.clients-slider-wrapper');
	$(() => {
		let minLogoCount = 1000;
		marqueeRows.each((ind, ele) => {
			const logoCount = $(ele).find('li').length;
			if (minLogoCount > logoCount) {
				minLogoCount = logoCount;
			}
		});
		marqueeRows.each((ind, ele) => {
			const marqueeRow = $(ele),
				marqueeDefaultSpeed = marqueeRow
					.find('.clients-list')
					.css('animation-duration'),
				marqueeItems = marqueeRow.find('li'),
				marqueeItemsCount = marqueeItems.length,
				marqueeSpeed =
					(parseFloat(marqueeDefaultSpeed) / minLogoCount) *
					marqueeItemsCount;
			marqueeRow
				.find('.clients-list')
				.append(marqueeItems.clone())
				.append(marqueeItems.clone())
				.addClass('start-marquee')
				.css('animation-duration', `${marqueeSpeed}s`);
		});
	});
})(jQuery);
