/*eslint-env jquery */
(($) => {
	const element = $('.bs-post-block--slider-arrow-hide'),
		slickArrow = '.slick-arrow';

	element.on('beforeChange', (e) => {
		$(e.target)
			.find(slickArrow)
			.css('opacity', '0');
	});
	element.on('afterChange', (e) => {
		$(e.target)
			.find(slickArrow)
			.css('opacity', '1');
	});
})(jQuery);
