/* eslint-env jquery */
/* eslint-disable no-unused-vars */
(($) => {
	const sliders = $('.bs-tab-slider--home-scroll-animation'),
		scrollFunc = (menu, contentEls) => {
			const menuTop = parseFloat(menu.css('top')),
				menuPaddingTop = parseFloat(menu.css('padding-top')),
				graceGap = 1;
			contentEls.each((ind, ele) => {
				const contentEl = $(ele),
					contentElId = contentEl.attr('data-slick-index'),
					contentElPos = contentEls[ind].getBoundingClientRect();
				if (
					Math.round(contentElPos.top) <=
					menuTop + menuPaddingTop + graceGap
				) {
					contentEls.removeClass('active-section');
					contentEl.addClass('active-section');
					menu.find('> .slick-slide-wrapper').removeClass(
						'active-tab'
					);
					menu.find(
						'> .slick-slide-wrapper[data-slick-index="' +
							contentElId +
							'"]'
					).addClass('active-tab');
				}
			});
		};
	$(() => {
		sliders.each((ind, ele) => {
			const thisSlider = $(ele),
				tabSlider = thisSlider.find('.bs-slider-tabs'),
				tabItems = tabSlider.find('.slick-slide-wrapper'),
				contentSlider = thisSlider.find('.bs-slider-content'),
				contentSlides = contentSlider.find('.slick-slide-wrapper');
			// mobile progress bar
			contentSlider.progressBar();
			//click Function
			tabItems.each((i, el) => {
				const tabItem = $(el);
				el.addEventListener('click', () => {
					const secId = tabItem.attr('data-slick-index'),
						secTop = contentSlides.eq(secId).offset().top,
						paddingTop = parseFloat(tabSlider.css('padding-top')),
						tabsTop = parseFloat(tabSlider.css('top'));
					$('html, body').animate({
						scrollTop: secTop - tabsTop - paddingTop,
					});
				});
			});
			//scroll Function
			contentSlides.eq(0).addClass('active-section');
			tabItems.eq(0).addClass('active-tab');
			$(window).on('scroll', function () {
				scrollFunc(tabSlider, contentSlides);
			});
		});
	});
	//progress bar plugin
	$.fn.progressBar = function () {
		if ($.fn.slick && typeof $.fn.slick !== 'function') return;
		const _this = this,
			_count = _this.find('.slick-slide-wrapper').length;
		_this
			.on('afterChange', (event, slick, currentSlide, nextSlide) => {
				const progressWidth = ((currentSlide + 1) / _count) * 100;
				_this
					.parent()
					.find('.active-slide-id')
					.text(currentSlide + 1);
				_this
					.parent()
					.find('.progress-anim')
					.css({
						width: progressWidth + '%',
					});
			})
			.on('init', function (event, slick) {
				const currSlide = slick.currentSlide;
				if (_this.parent().find('.progress-bar-wrapper').length === 0) {
					_this.parent().append(
						`
							<div class="progress-bar-wrapper">
								<div class="progress-bar">
									<span class="progress-anim"></span>
								</div>
								<div class="counter-wrapper">
									<span class="active-slide-id">${
										currSlide + 1
									}</span>/<span class="slide-count">${_count}</span>
								</div>
							</div>
						`
					);
				}
				_this
					.parent()
					.find('.progress-anim')
					.css({
						width: ((currSlide + 1) / _count) * 100 + '%',
					});
			});
	};
})(jQuery);
