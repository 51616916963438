/* eslint-disable prettier/prettier */
/* eslint-disable prefer-const */
(($) => {
  let $container = $('.bs-section--integrations-post-list .bs-row--integrations-list-row ');
  let containerWidth = $container.width(),
    $tiles = $('.bs-row--integrations-list-row .bs-posts__column'),
    tileWidth = $($tiles[0]).outerWidth(),
    tilePerRow = Math.round(containerWidth / tileWidth),
    openTile;
  const animateSpeed = 600;

  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  //Re Initiatie the values when window resize
  let reInitiateVal = () => {
    $container = $('.bs-section--integrations-post-list .bs-row--integrations-list-row');
    containerWidth = $container.width();
    $tiles = $('.bs-row--integrations-list-row .bs-posts__column');
    tileWidth = $($tiles[0]).outerWidth();
    tilePerRow = Math.round(containerWidth / tileWidth);
    //Set margin
    manageMargin($container.find('.pop-up-container'));
  };

  //when window resize 
  $(window).on('resize', function () {
    debounce(reInitiateVal(), 500);
  });

  //Replace Content of Popup
  const replaceContent = (popUp, popContent, tile) => {
    let $popUpOpened = popUp,
      $popContent = popContent,
      rightMargin = (($(window).width() - containerWidth) / 2) + 30;
    if ($(window).width() > 1440) {
      rightMargin = 150;
    } else {
      rightMargin = (($(window).width() - containerWidth) / 2) + 30;
    }
    $popUpOpened.empty().html($popContent.clone());
    // arrowhead position
    $container.find('.popup-arrow').remove();
    tile.append('<span class="popup-arrow"></span>');

    $popUpOpened.append('<span class="close-popup" style="right:' + rightMargin + 'px"></span>');
  };

  //closing the pop up
  let closePopup = () => {
    let $popUpOpened = $container.find('.pop-up-container');
    $popUpOpened.remove();
    highlightUrlParaChange('remove', null);
  };

  //Manage margin
  let manageMargin = (popUp) => {
    let marginOfSide,
      maxWidth = 1440;
    if ($(window).width() < maxWidth) {
      maxWidth = $(window).width();
    }
    marginOfSide = (maxWidth - containerWidth) / 2;
    if (typeof (popUp) !== 'undefined') {
      popUp.css({
        'width': maxWidth,
        'margin-left': -Math.abs(marginOfSide),
        'margin-right': -Math.abs(marginOfSide)
      })
    }
  };

  //Manage Video
  let manageVideo = (popUp) => {
    const $popUpWrapper = popUp,
      $vidoWrapper = $popUpWrapper.find('.video-wrapper');
    //html5 video
    $vidoWrapper.each((index, videoWrap) => {
      const $playBtn = $(videoWrap).find('.play-button'),
        $pauseBtn = $(videoWrap).find('.pause-button'),
        $video = $(videoWrap).find('video');

      $playBtn.on('click', () => {
        $video[0].play();
        $playBtn.addClass('hide');
        $pauseBtn.removeClass('hide');
      });
      $pauseBtn.on('click', () => {
        $video[0].pause();
        $playBtn.removeClass('hide');
        $pauseBtn.addClass('hide');
      });
    });
    //adding data attr to control form js
    $popUpWrapper.find('.media-elements iframe').each((index, element) => {
      const $this = $(element),
        $url = $this.attr('src'),
        changedUrl = `${$url}?enablejsapi=1`;
      $this.attr('src', changedUrl);
    });
  };

  const videoStatus = (e) => {
    if ($(e)[0].tagName.toLowerCase() === 'iframe') {
      if ($(e).attr('src').indexOf('vimeo') !== -1) {
        $(e)[0].contentWindow.postMessage(
          JSON.stringify({method: 'pause', value: true}),
          '*'
        );
      } else if ($(e).attr('src').indexOf('youtube') !== -1) {
        $(e)[0].contentWindow.postMessage(
          JSON.stringify({event: 'command', func: 'pauseVideo'}),
          '*'
        );
      } else if ($(e).attr('src').indexOf('wistia') !== -1) {
        $(e)[0].contentWindow.postMessage(
          JSON.stringify({method: 'pause', value: true}),
          '*'
        );
      }
    } else if ($(e)[0].tagName.toLowerCase() === 'video') {
      $(e).get(0).pause();
    }
  };

  //Open New Pop up
  let openNewPopup = (id, tile, tileIid) => {
    const tileIndex = tileIid;
    //adding pop up wrapper to place
    $('<div class="pop-up-container border" id="pop-up' + tileIndex + '"></div>')
      .insertAfter($tiles[id])
      .css('height', 0);

    let $popUpOpened = $container.find('.pop-up-container'),
      popContent = tile.find('.bs-post-intergration--popup');
    const thePopUpWrapper = $container.find('#pop-up' + tileIndex + '');
    //animating popup
    $popUpOpened.animate({
      height: popContent.outerHeight(),
    }, animateSpeed, function () {
      //end of animation
      let $slider = $popUpOpened.find('.bs-slider---default .slick-slider'),
        sliderData = $slider.data('slick');
      $popUpOpened.css('height', 'auto');
      //Scrolling body to top
      $('html, body').animate({scrollTop: tile.offset().top - 200}, animateSpeed);

      //Initiating the slider
      $slider.removeClass('slick-initialized');
      $slider.find('.slick-track > div').removeClass('slick-slide slick-current slick-active').removeAttr('style');
      $slider.find('button').remove();
      $slider.append($slider.find('.slick-track').html());
      $slider.find('.slick-list').remove();
      $slider.slick(sliderData);

      // Close BTN
      thePopUpWrapper.find('.close-popup').on('click', () => {
        closePopup();
        openTile = null;
        $container.find('.popup-arrow').remove();
      })

      // On scrolling pause all the videos
      const videos = document.querySelectorAll('[class^=embed-], video');
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            const $this = $(entry);
            videoStatus($this[0].target);
          }
        });
      });
      for (const video of videos) observer.observe(video);

      $slider.on('afterChange', function (event) {
        $(('[class^=embed-], video')).each((index, video) => {
          let $video = $(video);
          videoStatus($video[0]);
        });
      });

      //html5 video play/pause
      manageVideo(thePopUpWrapper);
    });
    //Set margin
    manageMargin(thePopUpWrapper);
    //Call to replace content
    replaceContent(thePopUpWrapper, popContent, tile);
    //Call animate scroll top in to view
    $('html, body').animate({scrollTop: tile.offset().top - 200}, animateSpeed);
    highlightUrlParaChange('add', popContent.data('post-slug'))
    urlHasHighlightParam = true;
  };

  const replacePopupWrapper = () => {
    $tiles.each((index, tile) => {
      let $elTile = $(tile);
      $elTile.on('click', (event) => {
        let placeOfPopUp = tilePerRow,
          $popUpOpend = $container.find('.bs-post-intergration--popup');

        $tiles.removeClass('popup-opened');
        $elTile.addClass('popup-opened');
        //preventing click same tile.
        if (openTile === index) {
          event.preventDefault();
          return false;
        }
        openTile = index;
        //find place of pop up appearing
        if (index + 1 > tilePerRow) {
          let calculatedPosition = Math.ceil((index + 1) / tilePerRow) * tilePerRow;
          if (calculatedPosition > $tiles.length) {
            placeOfPopUp = $tiles.length;
          } else {
            placeOfPopUp = calculatedPosition;
          }
        } else if ($tiles.length <= tilePerRow) {
          placeOfPopUp = $tiles.length;
        }
        //pop up already open
        if ($popUpOpend.length) {
          closePopup();
          openNewPopup(placeOfPopUp - 1, $elTile, index);
        } else {
          openNewPopup(placeOfPopUp - 1, $elTile, index);
        }
      });
    });
  };

  const popupLoadByURL = () => {
    const queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    const popupSlug = urlParams.get('highlight');
    if (urlParams.has('highlight') && popupSlug !== '') {
      $(('[data-post-slug="' + popupSlug + '"]')).parent().click();
      urlHasHighlightParam = true;
    }
  }

  const highlightUrlParaChange = (action, slug = null) => {
    const newUrl = new URL(window.location.href);
    if (action === 'add') {
      newUrl.searchParams.set('highlight', slug);
    } else {
      newUrl.searchParams.delete('highlight');
    }
    window.history.replaceState(null, null, newUrl);
  };

  let urlHasHighlightParam = false;

  document.addEventListener('facetwp-refresh', () => {
    if (urlHasHighlightParam) {
      window.history.pushState("Integration", "Integration", window.location.href.split('?')[0]);
      urlHasHighlightParam = false;
    }
  });

  $(document).on('facetwp-loaded', () => {
    reInitiateVal();
    replacePopupWrapper();
    popupLoadByURL();
  });
})(jQuery);
