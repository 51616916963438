$(() => {
	const sliderAccordionResetOnScroll = () => {
		$.each(
			$('.bs-slider-accordion-slick').closest('.bs-section'),
			(index, item) => {
				new Waypoint({
					element: item,
					handler() {
						const slickSlider = $(this.element).find(
							'.bs-slider-accordion-slick'
						);
						const slickObject = slickSlider.slick('getSlick');
						const initialSlide = slickObject.options.initialSlide;
						// Resetting only when current slide is greater than the initial slide
						if (
							slickObject.currentSlide > initialSlide &&
							slickObject.options.autoplay == true
						) {
							slickSlider.slick('slickGoTo', initialSlide);
						}
					},
					offset() {
						// Current window height + 50 pixels
						return this.context.innerHeight() + 50;
					},
				});
			}
		);
	};
	sliderAccordionResetOnScroll();
});
