/* eslint-env jquery */
/* global Waypoint, debounce */
require('slick-carousel-latest');

(function ($, w) {
	const initSlick = () => {
		if ($('[data-slick]').length) {
			$('[data-slick]')
				.not('.bs-slider-accordion-slick')
				.not('.slick-initialized')
				.slick();
		}
	};

	const autoPlaySpeedFunction = () => {
		$(
			'.bs-tab-slider--progress-bar :not(.bs-slider-content)[data-slick]'
		).each((index, ele) => {
			if ($(ele).hasClass('slick-initialized')) {
				const slick = $(ele).slick('getSlick');

				//append wrapper class and animator span to each slick slide element
				$.each($(ele).find('.slick-slide'), (i, element) => {
					const animatorElement = $(element).find(
						'.slick-slide-wrapper .slick-slide-animator'
					);
					animatorElement.unwrap();
					animatorElement.remove();

					$(element)
						.find('.slick-slide-wrapper')
						.children()
						.wrapAll(
							'<div class="slick-slide-title-wrapper"></div>'
						);
					const $content = $(element).find(
						'.slick-slide-title-wrapper'
					);
					$content.append(
						'<span class="slick-slide-animator"></span>'
					);
				});
				//set animator animation-duration
				if (slick.options.autoplay === true) {
					const playSpeed = slick.options.autoplaySpeed;
					$(ele)
						.find('.slick-slide-animator')
						.attr('data-speed', `${playSpeed}ms`)
						.css('animation-duration', `${playSpeed}ms`);
				}
			}
		});
	};

	// To refresh the initiated slick sliders
	const refreshSlick = () => {
		$('.slick-initialized').each(function (key) {
			if ($('.slick-initialized')[key] && $('.slick-initialized')[key].slick) {
				$('.slick-initialized')[key].slick.refresh();
			}
		});
	};

	const initWaypoint = () => {
		$.each(
			$(
				'.bs-tab-slider--progress-bar .bs-slider-tabs.slick-initialized'
			).closest('.bs-section'),
			(index, item) => {
				new Waypoint({
					element: item,
					handler() {
						const slickSlider = $(this.element).find(
							'.bs-slider-tabs.slick-initialized'
						);
						const slickObject = slickSlider.slick('getSlick');
						const initialSlide = slickObject.options.initialSlide;
						// Resetting only when current slide is greater than the initial slide
						if (slickObject.currentSlide > initialSlide) {
							slickSlider.slick('slickGoTo', initialSlide);
						}
					},
					offset() {
						// Current window height + 50 pixels
						return this.context.innerHeight() + 50;
					},
				});
			}
		);
	};

	const debouncedHandlers = () => {
		initSlick();
		autoPlaySpeedFunction();
		refreshSlick();
	};
	// Calls when the window is fully loaded
	$(w).on('load', () => {
		initSlick();
		autoPlaySpeedFunction();
		initWaypoint();
	});

	// Calls on window resize
	$(w).on('resize', debounce(debouncedHandlers, 500));
})(jQuery, window);
