const $ = require('jquery');

$.fn.mediaVideo = function () {
	this.find('iframe, video').each((index, element) => {
		const $this = $(element);
		$this.attr({ id: `embed-${index}` });
		$this.attr({
			allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
		});
	});

	const videoStatus = (e) => {
		if ($(e)[0].tagName.toLowerCase() === 'iframe') {
			if ($(e).attr('src').indexOf('vimeo') !== -1) {
				$(e)[0].contentWindow.postMessage(
					JSON.stringify({ method: 'pause', value: true }),
					'*'
				);
			} else if ($(e).attr('src').indexOf('youtube') !== -1) {
				$(e)[0].contentWindow.postMessage(
					JSON.stringify({ event: 'command', func: 'pauseVideo' }),
					'*'
				);
			} else if ($(e).attr('src').indexOf('wistia') !== -1) {
				$(e)[0].contentWindow.postMessage(
					JSON.stringify({ method: 'pause', value: true }),
					'*'
				);
			}
		} else if ($(e)[0].tagName.toLowerCase() === 'video') {
			$(e).get(0).pause();
		}
	};

	// On scrolling pause all the videos
	const videos = document.querySelectorAll('[id^=embed-]');
	const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (!entry.isIntersecting) {
				const $this = $(entry);
				videoStatus($this[0].target);
				$this[0].target.parentNode.parentNode.parentNode.classList.remove(
					'play-video'
				);
				$this[0].target.parentNode.parentNode.parentNode.children[1].style.display =
					'block';
			}
		});
	});
	for (const video of videos) observer.observe(video);

	// click on the placeholder image
	this.on('click', '> div > figure', (e) => {
		const parentItem = $(e.target).closest('div');

		this.find('iframe, video').each((index, element) => {
			const $this = $(element);
			videoStatus($this);
			$this.parents('div').removeClass('play-video');
			$this.parents('div').find('figure').fadeIn(400);
		});

		parentItem.addClass('play-video');
		$(e.target).closest('figure').fadeOut(400);

		parentItem.each((index, element) => {
			const $element = $(element);
			$element.find('iframe, video').each((index, ele) => {
				const $ele = $(ele);
				if ($ele[0].tagName.toLowerCase() === 'iframe') {
					const $src = $ele.attr('src');
					if ($src.indexOf('mute') !== -1) {
						if ($ele.attr('src').indexOf('vimeo') !== -1) {
							$ele[0].contentWindow.postMessage(
								JSON.stringify({ method: 'play', value: true }),
								'*'
							);
						} else if ($ele.attr('src').indexOf('youtube') !== -1) {
							$ele[0].contentWindow.postMessage(
								JSON.stringify({
									event: 'command',
									func: 'playVideo',
								}),
								'*'
							);
						} else if ($ele.attr('src').indexOf('wistia') !== -1) {
							$ele[0].contentWindow.postMessage(
								JSON.stringify({ method: 'play', value: true }),
								'*'
							);
						}
					} else {
						const $domainSrc =
							$src.indexOf('?') !== -1
								? $src.split('?')[0]
								: $src;
						const newSrc = `${$domainSrc}?enablejsapi=1&mute=0&autoplay=1&modestbranding=1&rel=0`;
						setTimeout(function () {
							$ele.attr('src', newSrc);
						}, 50);
					}
				} else if ($ele[0].tagName.toLowerCase() === 'video') {
					$(ele).get(0).play();
				}
			});
		});
	});
};
$('.bs-div--inline-video').mediaVideo();
$('.bs-media-element--home-banner-media').mediaVideo();
