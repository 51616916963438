/* eslint-env jquery */
(($) => {
	// check if an element in viewport
	$.fn.isInViewport = function () {
		const elementTop = $(this).offset().top;
		const elementBottom = elementTop + $(this).outerHeight();

		const viewportTop = $(window).scrollTop();
		const viewportBottom = viewportTop + $(window).height();

		return elementBottom > viewportTop && elementTop < viewportBottom;
	};

	const blurbWrapper = $('.bs-section--careers-hear-from-avionte');
	const blurb = $(
		'.bs-section--careers-hear-from-avionte .bs-advanced-blurb'
	);
	const blurbFirstItem = $(
		'.bs-section--careers-hear-from-avionte .bs-advanced-blurb:nth-child(1)'
	);
	const blurbCenterItem = $(
		'.bs-section--careers-hear-from-avionte .bs-advanced-blurb:nth-child(2)'
	);
	const blurbLastItem = $(
		'.bs-section--careers-hear-from-avionte .bs-advanced-blurb:nth-child(3)'
	);
	const blurbInner = $(
		'.bs-section--careers-hear-from-avionte .bs-advanced-blurb__container'
	);
	const blurbLink = $(
		'.bs-section--careers-hear-from-avionte .bs-advanced-blurb > a'
	);
	const winWidth = $(window).width();

	// wrap title, subtitle and description into one div
	$(blurb).each(function () {
		$(this)
			.find(
				'.bs-advanced-blurb__title, .bs-advanced-blurb__subtitle, .bs-advanced-blurb__description'
			)
			.wrapAll("<div class='av-content'/>");
	});

	// Screens above 768
	if (winWidth >= 768 && blurbWrapper.length > 0) {
		// add different classes to each element
		blurbFirstItem.addClass('av-left true');
		blurbCenterItem.addClass('av-middle true');
		blurbLastItem.addClass('av-right true');

		// onload add a class for pointer events none
		blurbLink.addClass('pointer-none');
		$('.av-middle').find('a').removeClass('pointer-none');

		// get height of the middle
		const blurbWrapperHeight = $(
			'.bs-section--careers-hear-from-avionte'
		).height();
		const blurbHeight = $(
			'.bs-section--careers-hear-from-avionte .bs-advanced-blurb'
		).height();

		let blurbCenterItemHeight = document.querySelector(
			'.bs-section--careers-hear-from-avionte .bs-advanced-blurb.av-middle'
		);
		const setTimer = function (temp, time) {
			setTimeout(function () {
				blurbWrapper.css('height', temp.clientHeight + 120 + 'px');
			}, time);
		};

		// variable declaration
		const avLeftBlurbCont = $('.av-left .bs-advanced-blurb__container');
		const avMiddleBlurbCont = $('.av-middle .bs-advanced-blurb__container');
		const avRightBlurbCont = $('.av-right .bs-advanced-blurb__container');

		setTimer(blurbCenterItemHeight, 800);

		let lastScrollTop = 0;
		$(window).on('scroll resize', function () {
			// large background animation
			const animImage = $(
				'.bs-section--careers-hear-from-avionte .bs-section__image-container'
			);
			const imagePosition = Math.round(
				($(window).scrollTop() / $(window).height()) * 100
			);

			if (animImage.isInViewport()) {
				$(animImage).css(
					'transform',
					'translateX(' + (imagePosition - 380) + 'px)'
				);
			}

			// blurb animation on scroll
			let st = $(this).scrollTop();

			if (blurb.isInViewport()) {
				if (st > lastScrollTop) {
					// scroll down
					if (blurb.isInViewport()) {
						avLeftBlurbCont.css(
							'transform',
							'translateY(' + 20 + 'px)'
						);
						avRightBlurbCont.css(
							'transform',
							'translateY(' + 15 + 'px)'
						);
						avMiddleBlurbCont.css(
							'transform',
							'translateY(' + 15 + 'px)'
						);
					}
				} else {
					// scroll up
					avLeftBlurbCont.css(
						'transform',
						'translateY(' + -20 + 'px)'
					);
					avRightBlurbCont.css(
						'transform',
						'translateY(' + -15 + 'px)'
					);
					avMiddleBlurbCont.css(
						'transform',
						'translateY(' + -15 + 'px)'
					);
				}
			}
			lastScrollTop = st;
		});

		// Carousel
		blurbWrapper.on('click', '.av-left', (e) => {
			e.preventDefault();
			const curHeight = document.querySelector('.av-left');
			blurbLink.addClass('pointer-none');
			$('.av-left')
				.removeClass('av-left true')
				.addClass('av-middle')
				.find('a')
				.removeClass('pointer-none');
			$('.av-middle.true')
				.removeClass('av-middle true')
				.addClass('av-left');
			$('.av-middle').delay('1000').addClass('true');
			setTimer(curHeight, 1200);
		});

		blurbWrapper.on('click', '.av-right', (e) => {
			e.preventDefault();
			const curHeight = document.querySelector('.av-right');
			blurbLink.addClass('pointer-none');
			$('.av-right')
				.removeClass('av-right true')
				.addClass('av-middle')
				.find('a')
				.removeClass('pointer-none');
			$('.av-middle.true')
				.removeClass('av-middle true')
				.addClass('av-right');
			$('.av-middle').delay('1000').addClass('true');
			setTimer(curHeight, 1200);
		});
	}
})(jQuery);
