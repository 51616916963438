(() => {

  const $sliderSections = $('.bs-section--add-accordion-slider-counter');
  if ($sliderSections.length != 0) {
    $sliderSections.each((i, secItem) => {
      const $sliderAccordion = $(secItem).find('.bs-slider-accordion');
      if ($sliderAccordion.length != 0) {
        $sliderAccordion.each((x, saItem) => {
          const $accordionParent = $(saItem);
          const $slickSliderItem = $accordionParent.find('.bs-slider-accordion-slick'); // slick slider

          const $accordionItem = $accordionParent.find('.bs-slider-accordion__floating-panel-container');
          if (!$accordionItem.find('.counter-container').length) {
            // create dynamic element to add counters
            $accordionItem.after(
              `<div class="counter-container">
                  <div class="counter-container__inner">
                    <span class="active">1</span> / <span class="total">
                  </div>
                </div>`
            );
          }
          // floating panel with content
          if ($slickSliderItem.length != 0) {
            $slickSliderItem.on('init reInit afterChange', function (event, slick, currentSlide) {
              if (currentSlide != undefined) {
                $accordionParent.find('.counter-container__inner .active').html(currentSlide + 1);
              }
            });
            $slickSliderItem.slick();
            const totalSlides = $accordionItem.children().length;;
            $accordionParent.find('.counter-container__inner .total').html(totalSlides);
          }
        });
      }
    });
  }
})();