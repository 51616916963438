$(document).ready(function () {
	const slider = $(
		'.bs-slider-accordion--trusted-over .bs-slider-accordion-slick'
	);
	const nestedSlider = $(
		'.bs-slider-accordion--trusted-over .bs-slider-accordion__floating-panel-container .slick-slider'
	);

	function onSliderAfterChange() {
		nestedSlider.slick('reinit');
	}

	function onSliderbeforeChange() {
		nestedSlider.each((index, element) => {
			let slderNav = $(element).find('.slick-arrow');

			slderNav.on('click', () => {
				$('.bs-slider-accordion--trusted-over .bs-slider-accordion__navigation-panel-container').slick('slickPause');
				slider.slick('slickPause');
			});
		});
	}


	slider
		.each(function (index, element) {
			var $element = $(element);
			$element.data('slider-length', $element.children().length);
		})
		.slick()
		.on('afterChange', onSliderAfterChange)
		.on('afterChange', onSliderbeforeChange);
});
