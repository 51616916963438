$(($) => {
  $('a').bind('click', (e) => {
    let link = $(e.currentTarget).attr('href');
    if (link === "#drift-chat") {
      e.preventDefault();
      if (typeof drift !== 'undefined') {
        drift.on('ready', (api, payload) => {
          api.openChat();
        });
      }
    }
  });
});