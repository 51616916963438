(function () {
  $(document).ready(() => {
    // Mobile progress bar
    const updateProgressStates = (
      sliderTotal,
      uniqSliderId,
      currentSlider
    ) => {
      if (sliderTotal != 0) {
        const columnSelector = $('#' + uniqSliderId).parents('.bs-column');
        let progressBarValue = (currentSlider / sliderTotal) * 100 + "%";
        columnSelector.find(".progress-inner").css({ width: progressBarValue });
        columnSelector.find('.progress-bar-slide-count').text(currentSlider + '/' + sliderTotal);
      }
    };

    $('.bs-section--add-progress-bar').each((index, element) => {
      const $this = $(element);
      let $slider, $sliderParent;
      if ($this.find('.bs-tab-slider').length) {
        $slider = $this.find('.bs-slider-content');
        $sliderParent = $slider.parent('.bs-tab-slider');
      }
      if ($this.find('.bs-slider').length) {
        $slider = $this.find('.slick-slider');
        $sliderParent = $slider.parent('.bs-slider');
      }
      if (!$this.find('.progress-bar-container').length) {
        $sliderParent.after(
          '<div class="progress-bar-container"><div class="progress-bar-wrapper"><span class="progress-inner"></span></div><span class="progress-bar-slide-count"></span></div>'
        );
      }
      // get json settings
      const slickJsonData = $slider.attr('data-slick');
      let getMobileSetting = {};
      if (slickJsonData) {
        const slickDataSet = JSON.parse($slider.attr('data-slick'));
        getMobileSetting = slickDataSet.responsive[1].settings;
      }

      let slideToShow = 1;
      let slideToScroll = 1;
      if (typeof getMobileSetting != undefined) {
        slideToShow = getMobileSetting.slidesToShow ? getMobileSetting.slidesToShow : slideToShow;
        slideToScroll = getMobileSetting.slidesToScroll ? getMobileSetting.slidesToScroll : slideToScroll;
      }

      $sliderParent.each((index, ele) => {
        let uniqSliderId = $(ele).find('.slick-slider').attr('id');

        let dataSlickLength = $(ele).find(
          '.slick-slider .slick-slide'
        ).length;

        updateProgressStates(dataSlickLength, uniqSliderId, slideToShow);

        const uniqueSliderElement = $('#' + uniqSliderId);
        uniqueSliderElement.on(
          'init reInit afterChange',
          (event, slick) => {
            let currentSlideNumber = 0,
              totalSlideCount = slick.slideCount ? slick.slideCount : 0,
              currentItem = slick.currentSlide + 1;

            if (slideToShow < 2) {
              currentSlideNumber = currentItem ? currentItem : slideToShow;
            } else {
              let totalNextItems = currentItem + slideToScroll;
              let checkCurrent = totalNextItems >= slideToShow ? currentItem + 1 : totalNextItems;
              currentSlideNumber = currentItem >= slideToShow ? checkCurrent : slideToShow;
            }
            updateProgressStates(
              totalSlideCount,
              uniqSliderId,
              currentSlideNumber
            );
          }
        );
        if (uniqueSliderElement.length != 0 & uniqueSliderElement) {
          uniqueSliderElement.slick('unslick');
          uniqueSliderElement.slick(uniqueSliderElement.data('slick'));
        }
      });
    });
  });
})();