/* global Waypoint */
/* eslint-env jquery, browser */
import { CountUp } from '../../node_modules/countup.js/dist/countUp.js';

let counterPlayed = true;

$(() => {
	function debounce(func, timeout = 300) {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				func.apply(this, args);
			}, timeout);
		};
	}

	function restartCounter() {
		$.each($('[data-counterup]'), (index, item) => {
			const dataOptions = item.getAttribute('data-options');
			const counterOptions = JSON.parse(dataOptions);
			new Waypoint({
				element: item,
				handler() {
					const countUp = new CountUp(
						item,
						counterOptions.endVal,
						counterOptions
					);
					if (!countUp.error) {
						countUp.start();
					}
				},
				offset: 'bottom-in-view',
			});
		});
	}

	restartCounter();

	$(window).bind('scroll', () => {
		const counterSection = $(
			'.bs-section--why-do-staffing-leaders, .bs-section--why-roi-platform'
		);
		if (
			counterSection.length > 0 &&
			$(window).scrollTop() >=
				counterSection.offset().top - counterSection.height()
		) {
			if (counterPlayed) {
				restartCounter();
			}
			counterPlayed = false;
		} else {
			counterPlayed = true;
		}
	});

	//bs-section--why-do-staffing-leaders
});
