(($) => {
	changeBackground = () => {
		const $section = $('.bs-section--platform-avionte');
		const $card = $section.find('.card');
		$card.on('click', function () {
			if (!$(this).hasClass('bg-active')) {
				$card.removeClass('bg-active');
				$('.bs-section--platform-avionte > .bs-div--platform-avionte').remove();
				$section.append(
					$('.card.active .bs-div--platform-avionte').clone()
				);
				$(this).addClass('bg-active');
			} else {
				$(this).removeClass('bg-active');
			}
			if ($('.bs-section--platform-avionte .bg-active').length <= 0) {
				$('.bs-section--platform-avionte > .bs-div--platform-avionte').remove();
				$section.append(
					$card.eq(0).find('.bs-div--platform-avionte').clone()
				);
			}
		});
	}
	changeBackground();
})(jQuery);
