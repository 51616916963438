/* eslint-env jquery */
/* eslint-disable no-unused-vars */
(($) => {
	const resizeFunc = () => {
		const tagItem = $('.bs-section--tags-control');
		const tagList = tagItem.find('.bs-post__category');
		const wrpWidth = tagList.parent().innerWidth() - 38;
		tagList.each((el, item) => {
			const els = $(item);
			const tags = els.find('span:not(.tag-dots)');
			let tabItemsWidth = 0;
			let enableDots = 'false';
			tags.each((tagId, tag) => {
				const tagEl = $(tag);
				if (els.find('.tag-dots') !== null) {
					els.find('.tag-dots').remove();
				}
				tagEl.removeClass('fs-hidden');
				tabItemsWidth = tabItemsWidth + tagEl.innerWidth() + 40;
				if (tabItemsWidth > wrpWidth) {
					tagEl.addClass('fs-hidden');
					enableDots = 'true';
				}
			});
			if (enableDots === 'true') {
				const newEl = $('<span></span>');
				newEl.addClass('tag-dots').text('...');
				newEl.insertAfter(tags.eq(tags.length - 1));
			}
		});
	};
	resizeFunc();
	$(window).on('resize facetwp-loaded load', function () {
		resizeFunc();
		// call resize after tags load
		setTimeout(() => {
			resizeFunc();
		}, 800);
	});
})(jQuery);
