// eslint-disable-next-line no-undef
$(($) => {
	const header = $('header');

	//Add Class on Scroll
	$(window).scroll(() => {
		if (window.pageYOffset >= 1) {
			header.addClass('scrolled');
		} else {
			header.removeClass('scrolled');
		}
	});

	//mobile close one menu on other expand
	$('header .mega-indicator').on('click', (e) => {
		let $link = $(e.target);
		let $parent = $link.parents('.mega-menu-item');
		if (!$parent.hasClass('mega-toggle-on')) {
			$parent.removeClass('mega-toggle-on');
			$link.parent().attr('aria-expanded', false);
		} else {
			$('.mega-toggle-on a').attr('aria-expanded', false);
			$('.mega-toggle-on').removeClass('mega-toggle-on');
			$parent.addClass('mega-toggle-on');
			$link.parent().attr('aria-expanded', true);
		}
	});

	let topMenuReplace = () => {
		const $topMenu = $('.menu-top-menu-container');
		const $menuItems = $topMenu.find('ul li');
		const $megaMenu = $('#mega-menu-main-menu');
		let menuReplaced = true;

		$(window).on('load resize', function (e) {
			if ($(window).width() < 1200) {
				if (menuReplaced) {
					$topMenu.hide();
					$menuItems.addClass('mega-menu-item temp-menu-item custom-menu-item');
					$megaMenu
						.find('> li:last-of-type')
						.before($menuItems.clone());
					$menuItems.find('a').addClass('mega-menu-link');
					menuReplaced = false;
				}
			} else if (!menuReplaced) {
				$topMenu.show();
				$menuItems.removeClass('mega-menu-item temp-menu-item custom-menu-item');
				$menuItems.find('a').addClass('mega-menu-link');
				$('#mega-menu-main-menu').find('.temp-menu-item').remove();
				menuReplaced = true;
			}
		});
	};

	topMenuReplace();
});
